.serverList {
  position: absolute;
  top: 5px;
  right: 5px;
  /* width: 400px; */
  z-index: 1001;
  /* border-radius: 5px; */
  background-color: rgb(194, 211, 237);
  cursor: default;
}

.serverListContent {
  background-color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.collapseBtn {
  position: absolute;
  width: 24px;
  padding-left: 4px;
  padding-right: 4px;
  cursor: pointer;
  font-size: 17px;
  bottom: -25px;
  right: 0px;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.serverRow {
  cursor: pointer;
  display: flex;
  transition: all 0.1s ease-in-out;
  background-color: white;
  padding-left: 1px;
  padding-right: 1px;
}

.serverRow:hover {
  transform: scale(1.01);
  color: blue;
}

.serverPilotsCount {
  font-weight: bold;
  padding-left: 1px;
  padding-right: 11px;
  color: rgb(17, 13, 13);
  display: flex;
}
.serverName {
  font-weight: bold;
  padding-left: 1px;
  padding-right: 1px;
  color: rgb(33, 28, 28);
  display: flex;
}
.serverTaskName {
  padding-left: 1px;
  padding-right: 1px;
  color: rgb(33, 28, 28);
  display: flex;
}
.serverTaskDistance {
  padding-left: 1px;
  padding-right: 1px;
  color: rgb(33, 28, 28);
  display: flex;
}
.serverActiveServer {
  font-weight: bold;
  padding-left: 1px;
  padding-right: 1px;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}
.serverInActiveServer {
  font-weight: bold;
  padding-left: 1px;
  padding-right: 1px;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}
