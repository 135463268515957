.container {
  /* min-height: 100vh; */
  /* padding: 0 0.5rem; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
  /* align-items: center; */
}

.main {
  /* padding: 5rem 0; */
  /* flex: 1; */
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: flex-start; */
  /* align-items: center; */
  /* width: 100%; */
}

.mainOverlayHud {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 9999;
}

.mainOverlayHud > div:first {
  border-radius: 4px;
  background-color: red;
}

.pilotInfo {
  display: flex;
  color: white;
  /* padding-left: 3px; */
  /* padding-top: 1px; */
  /* padding-bottom: 1px; */
  /* border-radius: 3px; */
  background-color: black;
}

.pilotFlightHud {
  display: flex;
  color: white;
  /* padding: 3px; */
  /* background-color: black; */
}

.rank {
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 3px; */
  width: 25px;
  height: 25px;
  text-align: center;
  font-weight: bold;
  background-color: white;
  color: black;
}

.pilotColorMark {
  width: 6px;
  border-radius: 2px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 6px;
  margin-right: 6px;
}
.pilotCN {
  background-color: black;
  width: 15px;
  font-weight: bold;
  margin-left: 3px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pilotName {
  background-color: #000000a4;;
  width: 150px;
  font-weight: bold;
  margin-left: 3px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.pilotStatusHangar {
  /* background-color: yellow; */
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pilotStatusWarmup {
  /* background-color: yellow; */
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pilotStatusRacing {
  background-color: rgba(34, 255, 0, 0.572);
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pilotStatusLanded {
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pilotStatusOutLanded {
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pilotStatusCrashed {
  background-color: red;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pilotStatusFinished {
  /* background-color: red; */
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pilotStatusHome {
  /* background-color: red; */
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pilotStatusFreefly {
  /* background-color: red; */
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pilotStatusFreeflyParagliding {
  /* background-color: red; */
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pilotStatusUnknown {
  background-color: grey;
  width: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.pilotAlt {
  background-color: #000000a4;
  font-weight: bold;
  width: 45px;
  padding-left: 3px;
  padding-right: 5px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: flex-end;
}

.pilotVarioPositive {
  width: 40px;
  display: flex;
  color: #00ff00;
  font-weight: bold;
  background-color: #000000a4;
  align-items: center;
  padding-right: 5px;
  justify-content: flex-end;
}

.pilotVarioNegative {
  width: 40px;
  display: flex;
  color: #ff6464;
  font-weight: bold;
  background-color: #000000a4;
  align-items: center;
  padding-right: 5px;
  justify-content: flex-end;
}

.pilotVarioZero {
  width: 40px;
  display: flex;
  color: #00ff00;
  background-color: #000000a4;
  align-items: center;
  padding-right: 5px;
  justify-content: flex-end;
}

.pilotVarioTitle {
  font-weight: bold;
  width: 40px;
  display: flex;
  color: #ffffff;
  background-color: #000000a4;
  align-items: center;
  padding-right: 5px;
  justify-content: flex-end;
}

.pilotCurrentSpeed {
  font-weight: bold;
  width: 40px;
  display: flex;
  background-color: #000000a4;
  align-items: center;
  /* justify-content: center; */
  justify-content: flex-end;
  padding-right: 5px;
}

@media (max-width: 575.98px) {
  .pilotName {
    display: none;
  }
  .pilotCurrentSpeed {
    display: none;
  }
  .pilotTaskTime {
    display: none;
  }
}

.pilotAvgSpeed {
  font-weight: bold;
  width: 45px;
  display: flex;
  background-color: #000000a4;
  align-items: center;
  /* justify-content: center; */
  justify-content: flex-end;
  padding-right: 5px;
}

.pilotDistance {
  font-weight: bold;
  width: 40px;
  display: flex;
  background-color: #000000a4;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5px;
}

.pilotTaskTime {
  /* font-weight: bold; */
  width: 55px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.492);
  align-items: center;
  justify-content: center;
}

.pilotPenalty {
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  display: flex;
  background-color: #ff17177a;
  align-items: center;
  justify-content: center;
}

.pilotGear {
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  display: flex;
  background-color: #3949517a;
  align-items: center;
  justify-content: center;
}

.pilotProp {
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  display: flex;
  background-color: #ff17177a;
  align-items: center;
  justify-content: center;
}
.pilotNoSignal {
  font-weight: bold;
  padding-left: 5px;
  padding-right: 5px;
  color: white;
  display: flex;
  background-color: #3949517a;
  align-items: center;
  justify-content: center;
}
.map {
  height: 100vh;
  width: 100%;
  background-color: transparent;
}

.taskInfo {
  position: absolute;
  z-index: 990;
  top: 5px;
  left: 5px;
  /* bottom: 150px; */
  /* overflow-y: auto;
  overflow-x: hidden; */
  
}

.popupCn {
  width: 40px;
  height: 40px;
  background-color: #ccc;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  color: #fff;
}

.collapseBtn {
  width: 24px;
  padding-left: 4px;
  cursor: pointer;
  font-size: 17px;
  top: 0px;
  z-index: 990;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.chatLine {
  margin-bottom: 0rem;
  font-size: small;
}

.buttonBadgeText {
  font-size: 10px;
}

.altChart {
  left: 65px;
  right: 70px;
  bottom: 50px;
  display: block;
  position: fixed;
  background-color: rgba(255, 255, 255, 0);
  z-index: 999;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.altChartTitleBar { 
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 10px;
  font-weight: bold;
  display: inline-table;
}

.weatherPoligon {
  fill-opacity: 0.3;
  fill:rgba(140, 194, 233, 0.566);
  stroke: rgb(189, 219, 241);
}


.weatherPoligon:hover {
  fill-opacity: 0.6; /* Change opacity on hover */
  stroke-width: 4px;
}


.airPenaltyZone {
  fill-opacity: 0.2;
  fill:rgb(243, 243, 7);
  stroke: rgb(243, 243, 7);
  color: #565454;
}

.airDefaultZone {
  fill-opacity: 0.1;
  fill:#000000a4;
  stroke: rgb(245, 245, 146);
}

.airDefaultZone:hover {
  fill-opacity: 0.3;
  stroke: rgb(255, 255, 62);
  stroke-width: 4px;
}

.redFillAirZone {
  fill-opacity: 0.1;
  fill:rgb(247, 13, 13);
  stroke: rgb(247, 13, 13);
}
.redFillAirZone:hover {
  fill-opacity: 0.2;
  stroke-width: 4px;
}

.redAirZone {
  fill-opacity: 0.1;
  fill:#000000a4;
  stroke: rgb(247, 13, 13);
}
.redAirZone:hover {
  fill-opacity: 0.2;
}

.greenAirZone {
  fill-opacity: 0.1;
  fill:#000000a4;
  stroke: rgb(80, 245, 138);
}
.greenAirZone:hover {
  fill-opacity: 0.2;
  stroke-width: 4px;
}

.blueAirZone {
  fill-opacity: 0.1;
  fill:#abd3faa4;
  stroke: rgba(59, 97, 220, 0.721);
}
.blueAirZone:hover {
  fill-opacity: 0.2;
  stroke-width: 4px;
}