.infoBar {
  display: flex;
  left: 0;
  right: 0;
  box-shadow: 0px 5px 10px 0px rgb(0 0 0/50%);
  position: fixed;
  background-color: white;
  justify-content: space-around;
  bottom: 0;
  z-index: 9999;
  height: 18px;
  font-size: 0.75rem;
  font-family: Helvetica Neue,Arial,Helvetica,sans-serif;
}

.collapseBtn {
  width: 24px;
  position: absolute;
  left: -20px;
  padding-left: 4px;
  cursor: pointer;
  font-size: 17px;
  top: 0px;
  z-index: 9999;
  background-color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

@media (max-width: 400px) {
  .hideFinishAlt {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .hideIt {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .infoBar .label {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .infoBar .label {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .infoBar .label {
    display: none;
  }
}




/* @media (max-width: 1399.98px) { ... } */
