/* .leaflet-popup-content {
    margin: 10px 10px 10px 10px;
} */

.leaflet-control-scale {
    left: 60px;
    bottom: 24px;
    position: fixed;
}

.leaflet-control-velocity {
    left: 150px;  
}

.leaflet-bottom {
    position: fixed;  
    bottom: 17px;
}
  
.leaflet-control-attribution{
    display: none;
}

.canvasjs-chart-credit{
    display: none;
}

.btn-group-sm > .btn, .btn-sm {
    --bs-btn-padding-y: 0.10rem;
    --bs-btn-padding-x: 0.15rem;
    --bs-btn-font-size: -4.125rem;
    --bs-btn-border-radius: var(--bs-border-radius-sm);
  }
  
@media (max-height: 659px) {
    .tab-content {
        max-height: 300px;
        overflow: auto;
    }
}

@media (min-height: 660px) and (max-height: 749px){
    .tab-content {
        max-height: 380px;
        overflow: auto;
    }    
}

@media (min-height: 750px) {
    .tab-content {
        max-height: 470px;
        overflow: auto;
    }    
}
